import React from "react";
import { Id } from "storefront/lib/Id";
import useAuthentication from "storefront/hooks/useAuthentication";
import useCollectionsForUser from "storefront/hooks/useCollectionsForUser";
import CollectionModule from "storefront/components/CollectionModule";
import styles from "./index.module.scss";

type Props = {
  userIdParam?: Id;
};

const CollectionsForUser = ({ userIdParam }: Props) => {
  const auth = useAuthentication();

  let userId;
  if (userIdParam) {
    userId = userIdParam;
  } else if (auth.type === "Authenticated") {
    userId = auth.user.id;
  } else if (auth.type === "Failed") {
    userId = null;
  } else {
    userId = undefined;
  }

  const personalizedCollectionsResource = useCollectionsForUser(userId);

  if (
    auth.type === "Loading" ||
    personalizedCollectionsResource.type !== "Completed"
  )
    return null;

  const { collections } = personalizedCollectionsResource.value;

  return (
    <div className={styles.collections}>
      {collections.map((collection) => (
        <CollectionModule collection={collection} />
      ))}
    </div>
  );
};

export default CollectionsForUser;
