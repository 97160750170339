import { ContentfulImage } from "storefront/Contentful/Image";
import type { Device } from "./index";

function matches(device: Device): (image: ContentfulImage) => boolean {
  return (item) => item.device === device;
}

const isFallback = matches("desktop");

function bestFor(
  device: Device,
): (image: Array<ContentfulImage>) => ContentfulImage | null | undefined {
  return (items) => {
    const asset = items.find(matches(device));
    if (asset) return asset;

    const fallbackAsset = items.find(isFallback);
    if (fallbackAsset) return fallbackAsset;

    return items[0];
  };
}

export default bestFor;
