import React from "react";
import { getId } from "../../../Contentful/Entry";
import ContentLaunchItem from "./ContentLaunchItem";
import Heading from "../Heading";
import Carousel from "../../Carousel";
import type {
  ContentLaunchItemCarousel as ContentLaunchItemCarouselType,
  ContentLaunchItem as ContentLaunchItemType,
} from "../../../Contentful/types";

type Props = {
  entry: ContentLaunchItemCarouselType;
};

/**
 * @name Modules.ContentLaunchItemCarousel
 * @description Renders the content launch items carousel on the trust microsite pages
 * @param {Props} props
 * @returns {React$Element<*>}
 */
const renderContentLaunchItems = (items: Array<ContentLaunchItemType>) =>
  items.map((item) => <ContentLaunchItem key={getId(item)} entry={item} />);

const ContentLaunchItemCarousel = ({ entry }: Props) => {
  const { title, items } = entry.fields;
  return (
    <div className="Module--ContentLaunchItemCarousel">
      <Heading title={title} />
      <Carousel
        baseClassName="ContentLaunchItemCarousel"
        itemsToShow={3}
        carouselItems={renderContentLaunchItems(items)}
      />
    </div>
  );
};

export default ContentLaunchItemCarousel;
