import React from "react";
import Image from "storefront/components/Image";
import LaunchItem from "./LaunchItem";
import type { ImageItem as ImageItemType } from "./types";

type Props = {
  entry: ImageItemType;
};

const ImageItem = ({ entry }: Props) => {
  const { imageUrl, launchItem } = entry.fields;
  return (
    <div className="image-item">
      <Image
        src={imageUrl}
        alt="ImageItem"
        className="-image"
        rotate={0}
        width={380}
        sizes="(min-width: 481px) 33vw, 100vw"
        fit="clip"
        style={{
          maxHeight: "253px",
          height: "auto",
        }}
      />
      {launchItem ? <LaunchItem entry={launchItem} /> : null}
    </div>
  );
};

export default ImageItem;
