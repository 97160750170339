import React from "react";
import { Id } from "storefront/lib/Id";
import { PageType } from "storefront/Analytics/Event";
import {
  EmbeddedFeature,
  ModuleEntry,
  EMBEDDED_FEATURE,
  PERSONALIZED_COLLECTION_GRID,
  TICKER,
} from "storefront/Contentful/types";
import { getId } from "storefront/Contentful/Entry";
import Ticker from "storefront/components/Ticker";
import CollectionsForUser from "storefront/components/CollectionsForUser";
import AlertBanner, { ALERT_BANNER } from "storefront/components/AlertBanner";
import BigVideoModule, { BIG_VIDEO } from "../BigVideoModule";
import CategoryGrid, { CATEGORY_GRID } from "../CategoryGrid";
import DCOBlock, { DCO_BLOCK } from "../DCOBlock";
import DCOEntryPoint, { DCO_ENTRY_POINT } from "../DCOEntryPoint";
import DesignerGrid, { DESIGNER_GRID } from "../DesignerGrid";
import DesignersForYou, { DESIGNERS_FOR_YOU } from "../DesignersForYou";
import DiscoverDailyCarousel, {
  DISCOVER_DAILY_CAROUSEL,
} from "../DiscoverDailyCarousel";
import FeaturedCapsules, { FEATURED_CAPSULES } from "../FeaturedCapsules";
import FeaturedContentModule from "../FeaturedContentModule";
import { FEATURED_CONTENT } from "../FeaturedContentModule/FeaturedContentModuleEntry";
import FeedCarousel, { FEED_CAROUSEL } from "../FeedCarousel";
import FeedGrid, { FEED_GRID } from "../FeedGrid";
import FullWidthCTA, { FULL_WIDTH_CTA } from "../FullWidthCTA";
import GuideScreen from "../Guide/GuideScreen";
import { GUIDE_SCREEN } from "../Guide/types";
import ListingsFromFeed, { LISTINGS_FROM_FEED } from "../ListingsFromFeed";
import RecentlyViewed, { RECENTLY_VIEWED } from "../RecentlyViewed";
import VisualFilters, { VISUAL_FILTERS } from "../VisualFilters";
import ContentfulErrorBoundary from "../ContentfulModuleWrapper/ContentfulErrorBoundary";
import HeroCarousel, { HERO_CAROUSEL } from "../HeroCarousel";

type EmbeddedWrapperProps = {
  entry: EmbeddedFeature;
};

const EmbeddedWrapper = ({ entry }: EmbeddedWrapperProps) => {
  const { fields } = entry;
  const { moduleId } = fields;

  switch (moduleId) {
    case TICKER:
      return <Ticker text="🔥 Daily Grails for You" />;
    case PERSONALIZED_COLLECTION_GRID:
      return <CollectionsForUser />;
    default:
      ((_: never): void => {})(moduleId);
      return null;
  }
};

type ModuleComponentProps = {
  position?: number;
  from?: string;
  pageType?: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
  entry?: ModuleEntry;
};

const contentfulContentTypeToModuleComponent: Record<
  string,
  React.ComponentType<ModuleComponentProps>
> = {
  [ALERT_BANNER]: AlertBanner,
  [BIG_VIDEO]: BigVideoModule,
  [CATEGORY_GRID]: CategoryGrid,
  [DCO_BLOCK]: DCOBlock,
  [DCO_ENTRY_POINT]: DCOEntryPoint,
  [DESIGNER_GRID]: DesignerGrid,
  [DESIGNERS_FOR_YOU]: DesignersForYou,
  [DISCOVER_DAILY_CAROUSEL]: DiscoverDailyCarousel,
  [EMBEDDED_FEATURE]: EmbeddedWrapper,
  [FEATURED_CAPSULES]: FeaturedCapsules,
  [FEATURED_CONTENT]: FeaturedContentModule,
  [FEED_CAROUSEL]: FeedCarousel,
  [FEED_GRID]: FeedGrid,
  [FULL_WIDTH_CTA]: FullWidthCTA,
  [GUIDE_SCREEN]: GuideScreen,
  [HERO_CAROUSEL]: HeroCarousel,
  [LISTINGS_FROM_FEED]: ListingsFromFeed,
  [RECENTLY_VIEWED]: RecentlyViewed,
  [VISUAL_FILTERS]: VisualFilters,
};

const renderModule =
  (
    from: string,
    pageType?: PageType,
    pageTypeIdentifier?: Id,
    pageTypeName?: string,
  ) =>
  (module: ModuleEntry, index: number) => {
    const Module = contentfulContentTypeToModuleComponent[module.contentType];

    if (!Module) return null;

    return (
      <ContentfulErrorBoundary key={getId(module)}>
        <Module
          key={getId(module)}
          position={index}
          from={from}
          entry={module}
          pageType={pageType}
          pageTypeIdentifier={pageTypeIdentifier}
          pageTypeName={pageTypeName}
        />
      </ContentfulErrorBoundary>
    );
  };

export default renderModule;
