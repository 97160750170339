import memoize from "../Function/memoize";

type Window = typeof window;
const WEBP_DATA_SRC =
  "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";

/**
 * @private
 * @param {Window} window - this is only here so that we can pass a mock window for testing
 */
export const checkWebpSupport = ({
  createImageBitmap,
  Image,
}: Window = window): Promise<boolean> => {
  if (!createImageBitmap || !Image) return Promise.resolve(false);
  return new Promise((resolve) => {
    const image = new Image();

    const onSuccess = () => resolve(true);

    const onError = () => resolve(false);

    const onLoad = () =>
      createImageBitmap(image).then(onSuccess).catch(onError);

    image.onload = onLoad;
    image.onerror = onError;
    // Setting the src of an instance of Image triggers the browser to kick off a request for the image, even if the Image element is not in the DOM.
    image.src = WEBP_DATA_SRC;
  });
};

/**
 * @name isWebpSupported
 * @memberof Browser
 * @description Asychronously checks to see if the browser supports the webp image format. Please note that this is the memoized version of this function; this means that, no matter how many times it is called, it will always return the return value of the first time it is called.
 *
 * Read more about webp: https://developers.google.com/speed/webp
 *
 * @returns {Promise<boolean>} a Promise that resolves to true if webp is supported
 *
 * @example
 * const Image = ({ src, ...rest }) => {
 *   const [webp, setWebp] = useState("unknown");
 *
 *   useEffect(() => {
 *     isWebpSupported()
 *       .then((supported) => setWebp(supported ? "supported" : "unsupported"))
 *       .catch(() => setWebp("unsupported"));
 *   }, []);
 *
 *   let actualSrc;
 *   switch (webp) {
 *     case "supported":
 *       actualSrc = src + "?format=webp";
 *
 *     case "unsupported":
 *       actualSrc = src;
 *
 *     case "unknown":
 *     default:
 *       actualSrc = "";
 *       break;
 *   }
 *
 *   return <img src={actualSrc} {...rest} />;
 * };
 */
const isWebpSupported: () => Promise<boolean> = memoize(checkWebpSupport);
export default isWebpSupported;
