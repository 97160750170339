import React from "react";
import cn from "classnames";
import { Icon } from "storefront/Icon";
import StarOutline from "./StarOutline";
import HalfStar from "./HalfStar";
import Star from "./Star";

type Props = Icon & {
  fill?: string;
  variant: "empty" | "half" | "full";
};

const RatingStar = ({ width, height, fill, variant, className }: Props) => {
  switch (variant) {
    case "empty":
      return (
        <StarOutline
          width={width}
          height={height}
          fill={fill}
          className={cn("Star", className)}
        />
      );

    case "half":
      return (
        <HalfStar
          width={width}
          height={height}
          fill={fill}
          className={cn("Star", className)}
        />
      );

    case "full":
      return (
        <Star
          width={width}
          height={height}
          fill={fill}
          className={cn("Star", className)}
        />
      );

    default:
      return <></>;
  }
};

export default RatingStar;
