import React from "react";
import ImageItem from "./ImageItem";
import type { GuideSingleImageModule as GuideSingleImageModuleType } from "./types";

type Props = {
  entry: GuideSingleImageModuleType;
};

const GuideSingleImageModule: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({ entry }) => {
  const { imageItem } = entry.fields;
  return (
    <div className="Module--GuideSingleImage">
      <ImageItem entry={imageItem} />
    </div>
  );
};

export default GuideSingleImageModule;
