import type { Id } from "storefront/lib/Id";
import { get } from "storefront/GrailedAPI/request";

type Data = {
  designerIds: Array<Id>;
  personalized: boolean;
};

const getRecommendedDesignerIds = (userId: Id): Promise<Data> =>
  get(`/api/users/${userId}/recommendations/designers`).then((response) => ({
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    designerIds: response.data.designerIds,
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    personalized: response.data.personalized,
  }));

export default getRecommendedDesignerIds;
