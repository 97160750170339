import { Asset } from "contentful";
import { Id } from "storefront/lib/Id";
import { AlertBannerModule } from "storefront/components/AlertBanner";
import { CategoryGridModule } from "storefront/components/Modules/CategoryGrid";
import { DCOBlockModule } from "storefront/components/Modules/DCOBlock";
import { DCOEntryPointModule } from "storefront/components/Modules/DCOEntryPoint";
import { DesignerGridModule } from "storefront/components/Modules/DesignerGrid";
import { DesignersForYouModule } from "storefront/components/Modules/DesignersForYou";
import { DiscoverDailyCarouselModule } from "storefront/components/Modules/DiscoverDailyCarousel";
import { FeaturedCapsulesModule } from "storefront/components/Modules/FeaturedCapsules";
import FeaturedContentModule from "storefront/components/Modules/FeaturedContentModule/FeaturedContentModuleEntry";
import { FeedCarouselModule } from "storefront/components/Modules/FeedCarousel";
import { FeedGridModule } from "storefront/components/Modules/FeedGrid";
import { FullWidthCTAModule } from "storefront/components/Modules/FullWidthCTA";
import {
  GuideScreen,
  GuideSingleImageModule,
} from "storefront/components/Modules/Guide/types";
import { ListingsFromFeedModule } from "storefront/components/Modules/ListingsFromFeed";
import { RecentlyViewedModule } from "storefront/components/Modules/RecentlyViewed";
import { VisualFiltersModule } from "storefront/components/Modules/VisualFilters";
import { HeroCarouselEntry } from "storefront/components/Modules/HeroCarousel";
import { BigVideoModuleEntry } from "storefront/components/Modules/BigVideoModule";
import { TypedEntry } from "./TypedEntry";

export const CONTENTFUL_PAGE = "page" as const;

export const EMBEDDED_FEATURE = "announcement" as const;

export const FEED_ITEM = "feedItem" as const;

export const PERSONALIZED_COLLECTION_GRID =
  "personalizedCollectionFullGrid" as const;

export const TICKER = "ticker" as const;

type EmbeddedFeatureModuleId =
  | typeof TICKER
  | typeof PERSONALIZED_COLLECTION_GRID;

export type EmbeddedFeature = TypedEntry<
  typeof EMBEDDED_FEATURE,
  { name: string; moduleId: EmbeddedFeatureModuleId }
>;

export type ModuleEntry =
  | AlertBannerModule
  | BigVideoModuleEntry
  | CategoryGridModule
  | DCOBlockModule
  | DCOEntryPointModule
  | DesignerGridModule
  | DesignersForYouModule
  | DiscoverDailyCarouselModule
  | EmbeddedFeature
  | FeaturedCapsulesModule
  | FeaturedContentModule
  | FeedCarouselModule
  | FeedGridModule
  | FullWidthCTAModule
  | GuideScreen
  | HeroCarouselEntry
  | ListingsFromFeedModule
  | RecentlyViewedModule
  | VisualFiltersModule;

export type PageEntry = TypedEntry<
  typeof CONTENTFUL_PAGE,
  {
    modules: Array<ModuleEntry>;
  }
>;

export type FeedItemEntry = TypedEntry<
  typeof FEED_ITEM,
  {
    feedId: string;
    image: Asset;
    title: string;
    name: string;
    href?: string;
  }
>;

export type ItemType =
  | "listing card"
  | "feed item"
  | "CTA"
  | "category"
  | "designer"
  | "article card"
  | "capsule card"
  | "popular collaboration"
  | "popular search query"
  | "designer category";

export type TrackingItemProperties = {
  itemType: ItemType;
  itemPosition: number | string;
  itemName: string;
  itemNameContentful: string;
  imageUrl: string;
};

export type ContentLaunchItem = TypedEntry<
  "contentLaunchItem",
  {
    title: string;
    description: string;
    url: string;
    entryId: string;
    image: GuideSingleImageModule;
  }
>;

export type ContentLaunchItemCarousel = TypedEntry<
  "carousel",
  {
    title: string;
    items: Array<ContentLaunchItem>;
  }
>;

export type FrequentlyAskedQuestionModule = TypedEntry<
  "frequentlyAskedQuestion",
  {
    question: string;
    answer: string;
  }
>;

export type UserTestimonial = TypedEntry<
  "testimonialUser",
  {
    name: string;
    reviewFrom: string;
    headline: string;
    quote: string;
    rating: number;
    route: string;
  }
>;

export type PressTestimonial = TypedEntry<
  "testimonialPress",
  {
    publicationName: string;
    quote: string;
    articleTitle: string;
    route: string;
    logo: Asset;
    articleImage: Asset;
  }
>;

export type TestimonialCarousel = TypedEntry<
  "moduleTestimonialCarousel",
  {
    name: string;
    userTestimonials?: Array<UserTestimonial>;
    pressTestimonials?: Array<PressTestimonial>;
  }
>;

export type GiveawayListingItemModuleEntry = TypedEntry<
  "moduleGiveawayListingItem",
  {
    brand: string;
    title: string;
    size?: string;
    images: Array<Asset>;
    description: string;
    listingId: Id;
    buttonTextEntered: string;
    buttonTextUnentered: string;
  }
>;
