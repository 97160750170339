import { map, update, uniqueId } from "lodash/fp";
import type { Id } from "storefront/lib/Id";

type Identified<T> = T & {
  id: Id;
};

/**
 * @description Pass this little helper an array of objects that have an id key
 * It will return to you the same array, but now each of the objects has a unique id!
 * This is particularly useful if you're making a loading state and have an array of loading objects
 * each w/ the same id, and there is a key using the id.
 * Now all the keys will be unique! Magical.
 */
const setUniqueIds: <T>(arg0: Array<Identified<T>>) => Array<Identified<T>> =
  map(update("id", uniqueId));
export default setUniqueIds;
