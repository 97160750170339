import React, { ReactElement } from "react";
import Body from "storefront/components/Body";

type ParagraphProps = {
  children: React.ReactElement<React.ComponentProps<any>, any>;
};
export const Paragraph =
  (customClassName: string) =>
  ({ children }: ParagraphProps) =>
    <p className={customClassName}>{children}</p>;
type LinkProps = {
  href: string;
  children: React.ReactElement<React.ComponentProps<any>, any>;
};

const isNoFollowLink = (href: string): boolean => href.split("/")[1] === "sold";

export const Link =
  (customClassName: string, target = "_blank") =>
  ({ href, children }: LinkProps) => {
    const rel = isNoFollowLink(href)
      ? "nofollow noopener noreferrer"
      : "noopener noreferrer";
    return (
      /* disabling due to incorrect warning message, we do add rel noreferrer */

      /* eslint-disable-next-line react/jsx-no-target-blank */
      <a className={customClassName} href={href} rel={rel} target={target}>
        {children}
      </a>
    );
  };
type ListItemProps = {
  children: React.ReactElement<React.ComponentProps<any>, any>;
};
export const ListItem =
  (customClassName: string) =>
  ({ children }: ListItemProps) =>
    <li className={customClassName}>{children}</li>;

type BodyTextProps = {
  children: ReactElement<HTMLOrSVGElement>;
};

export const BodyText =
  (customClassName: string) =>
  ({ children }: BodyTextProps) =>
    <Body className={customClassName}>{children}</Body>;
