import React from "react";
import NextLink from "next/link";
import { times } from "lodash/fp";
import { CollectionDisplay } from "storefront/Collection";
import { PHOTO_PLACEHOLDER_URL } from "storefront/Listing";
import ImageV2 from "storefront/components/ImageV2";
import Subhead from "storefront/components/Subhead";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./index.module.scss";

const PHOTO_SLOTS = 5;

type Props = {
  collection: CollectionDisplay;
};

const CollectionModule = ({ collection }: Props) => (
  <NextLink href={`/collections/${collection.id}`} className={styles.link}>
    <Subhead className={styles.title}>{collection.title}</Subhead>

    <div className={styles.images}>
      {times((index) => {
        const photo = collection.displayPhotos[index];
        const renderOverlay =
          index === PHOTO_SLOTS - 2 || index === PHOTO_SLOTS - 1;

        return (
          <div className={styles[`image-${index + 1}`]}>
            <ImageV2
              src={photo?.url || PHOTO_PLACEHOLDER_URL}
              alt={photo?.url || PHOTO_PLACEHOLDER_URL}
              fill
              fit="crop"
              sizes="175px"
              rotate={photo?.rotate}
              priority
              className={styles.img}
            />

            {renderOverlay ? (
              <div className={styles.viewMore}>+ View More</div>
            ) : null}
          </div>
        );
      }, PHOTO_SLOTS)}
    </div>
  </NextLink>
);

export default CollectionModule;
