import React from "react";
import classnames from "classnames";
import Caret from "storefront/components/Icons/Navigation/Caret";
import onlyOnEnterKey from "storefront/lib/onlyOnEnterKey";
import { ChildProps } from "./Collapsible";

type Props = {
  title: string;
  className?: string;
};

/**
 * @name Collapsible.Header
 * @description A reusable component that renders the header
 * for a Collapsible component.
 * @param {Props} props
 */
const CollapsibleHeader =
  ({ title, className }: Props) =>
  ({ isCollapsed, toggle }: ChildProps) =>
    (
      <div
        className={classnames("filter-top", {
          _collapsed: isCollapsed,
        })}
      >
        <div
          className="-collapsible-target"
          onClick={toggle}
          onKeyPress={onlyOnEnterKey(toggle)}
          role="button"
          tabIndex={0}
        >
          <Caret
            direction={isCollapsed ? "down" : "up"}
            className="toggle"
            height="9px"
            width="9px"
          />
          <h4 className={classnames("--title", className)}>{title}</h4>
        </div>
      </div>
    );

export default CollapsibleHeader;
