import React from "react";
import Marquee from "react-fast-marquee";
import styles from "./index.module.scss";

type Props = {
  text: string;
};

const Ticker = ({ text }: Props) => (
  <Marquee speed={25} autoFill className={styles.ticker}>
    {text}
  </Marquee>
);

export default Ticker;
