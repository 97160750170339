import React, { Fragment } from "react";
import { map } from "lodash/fp";
import { getId } from "storefront/Contentful/Entry";
import GuideCardModule from "storefront/components/Modules/Guide/GuideCardModule";
import GuideCardTextModule from "storefront/components/Modules/Guide/GuideCardTextModule";
import GuideTextModule from "storefront/components/Modules/Guide/GuideTextModule";
import GuideImageModule from "storefront/components/Modules/Guide/GuideImageModule";
import GuideSingleImageModule from "storefront/components/Modules/Guide/GuideSingleImageModule";
import ContentLaunchItemCarousel from "storefront/components/Modules/ContentLaunchItemCarousel";
import GuideSectionWrapper from "storefront/components/Modules/Guide/GuideSectionWrapper";
import TestimonialCarousel from "storefront/components/Modules/TestimonialCarousel";
import ContentfulErrorBoundary from "storefront/components/Modules/ContentfulModuleWrapper/ContentfulErrorBoundary";
import BackgroundImage from "storefront/components/Image/BackgroundImage";
import {
  GuideScreenModule as GuideScreenModuleType,
  GuideScreen as GuideScreenType,
} from "storefront/components/Modules/Guide/types";

type Props = {
  entry: GuideScreenType;
};

const renderGuideScreenModule = (moduleProps: GuideScreenModuleType) => {
  switch (moduleProps.contentType) {
    case "guideImageModule":
      return (
        <ContentfulErrorBoundary key={getId(moduleProps)}>
          <GuideImageModule entry={moduleProps} />
        </ContentfulErrorBoundary>
      );

    case "guideCardTextModule":
      return (
        <ContentfulErrorBoundary key={getId(moduleProps)}>
          <GuideCardTextModule entry={moduleProps} />
        </ContentfulErrorBoundary>
      );

    case "guideTextModule":
      return (
        <ContentfulErrorBoundary key={getId(moduleProps)}>
          <GuideTextModule entry={moduleProps} />
        </ContentfulErrorBoundary>
      );

    case "guideCardModule":
      return (
        <ContentfulErrorBoundary key={getId(moduleProps)}>
          <GuideCardModule entry={moduleProps} />
        </ContentfulErrorBoundary>
      );

    case "guideSingleImageModule":
      return (
        <ContentfulErrorBoundary key={getId(moduleProps)}>
          <GuideSingleImageModule entry={moduleProps} />
        </ContentfulErrorBoundary>
      );

    case "carousel":
      return (
        <ContentfulErrorBoundary key={getId(moduleProps)}>
          <ContentLaunchItemCarousel
            entry={moduleProps}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ entry: ContentLaunchItemCarousel; onLinkCl... Remove this comment to see the full error message
            onLinkClick={() => {}}
          />
        </ContentfulErrorBoundary>
      );

    case "sectionWrapper":
      return (
        <ContentfulErrorBoundary key={getId(moduleProps)}>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ entry: GuideSectionWrapper; onLinkClick: (... Remove this comment to see the full error message */}
          <GuideSectionWrapper entry={moduleProps} onLinkClick={() => {}} />
        </ContentfulErrorBoundary>
      );

    case "moduleTestimonialCarousel":
      return (
        <ContentfulErrorBoundary key={getId(moduleProps)}>
          <TestimonialCarousel entry={moduleProps} />
        </ContentfulErrorBoundary>
      );

    default:
      return <Fragment key={getId(moduleProps)} />;
  }
};

const renderGuideScreenModules = (modules: [GuideScreenModuleType]) => (
  <div className="GuideScreen--Modules">
    {map(renderGuideScreenModule, modules)}
  </div>
);

const HeroImage = ({ heroUrl }: { heroUrl: string }) => (
  <div className="-hero-image">
    <BackgroundImage
      TagName="div"
      className="-image"
      sources={{ desktop: { url: heroUrl } }}
    />
  </div>
);

const ContentGuideScreen = ({ entry }: Props) => {
  const { heroUrl, title, description, modules } = entry.fields;
  return (
    <>
      {heroUrl ? <HeroImage heroUrl={heroUrl} /> : null}
      <div className="Module--GuideScreen">
        <h1 className="-title">{title}</h1>
        <p className="-description">{description}</p>
        {renderGuideScreenModules(modules)}
      </div>
    </>
  );
};

export default ContentGuideScreen;
