import { Id } from "storefront/lib/Id";
import { Article } from "storefront/Article";

export type ArticleClickedEvent = {
  object: "article";
  action: "clicked";
  properties: {
    id: Id;
    content_type: string;
    title: string;
    subtitle: string;
    slug: string;
    author: string;
    tags: string;
    published_at: string;
    franchise: string;
    from: string;
  };
};

/**
 * @description Creates a "article clicked" event that can be given to `track` to record the event with our third-party event tracking services.
 *
 * @example
 *   const event = articleClicked(article, "homepage");
 *   track(event);
 */
const articleClicked = (
  article: Article,
  from: string,
): ArticleClickedEvent => ({
  object: "article",
  action: "clicked",
  properties: {
    id: article.id,
    content_type: article.contentType,
    title: article.title,
    subtitle: article.subtitle,
    slug: article.slug,
    author: article.author,
    tags: article.tagList.join(", "),
    published_at: article.publishedAt,
    franchise: article.franchise,
    from,
  },
});

export default articleClicked;
