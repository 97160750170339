import React, { ComponentType, useEffect, useState } from "react";
import { createClient } from "contentful";
import { head } from "lodash/fp";
import { Resource, loading, completed, failed } from "storefront/lib/Resource";
import { Config } from "storefront/Contentful/Config";
import { TypedEntry, fromEntry } from "storefront/Contentful/TypedEntry";
import { ContentfulError } from "storefront/Contentful/ContentfulError";
import { Query } from "storefront/Contentful/Query";

/**
 * In order to make this "contentfulized" components a little easier to use,
 * the component Props will come in two flavours: RootNode and LeafNode.
 * RootNodes are components that make requests to Contentful and require a
 * Query in the props. LeafNodes accept an Entry as props to render data
 * from a parent. A wrapped component will function as either - depending on
 * the props it is given.
 */
type RootNodeProps<T extends TypedEntry<string, unknown>> = {
  config: Config;
  entry?: never;
  query: Query;
};

type LeafNodeProps<T extends TypedEntry<string, unknown>> = {
  config?: never;
  entry?: T;
  query?: never;
};

type ExternalProps<T extends TypedEntry<string, unknown>> =
  | RootNodeProps<T>
  | LeafNodeProps<T>;

function isRootNodeProps<T extends TypedEntry<string, unknown>>(
  props: ExternalProps<T>,
): props is RootNodeProps<T> {
  return !!props.query;
}

export type WithContentfulProps<T extends TypedEntry<string, unknown>> = {
  error?: Error | ContentfulError;
  entry?: T;
};

/**
 * Wraps a component with contentful-specific behaviors.
 *
 * @example
 * const ContentComponent = (props: { entry: CoolEntry }) => <h1>{ props.entry.title }</h1>;
 * const ContentfulizedComponent = withContentful(ContentComponent, config);
 * // ... later in a `render` function
 * render() {
 *   return <ContentfulizedComponent query={{ 'sys.id': entryId }} />
 * }
 */
const withContentful = <T extends TypedEntry<string, unknown>, OP = {}>(
  contentType: T["contentType"],
  WrappedComponent: ComponentType<OP & WithContentfulProps<T>>,
): ComponentType<OP & ExternalProps<T>> => {
  const RootNode = ({ query, config, ...props }: OP & RootNodeProps<T>) => {
    const [entry, setEntry] = useState<Resource<T>>(loading);

    useEffect(() => {
      const client = createClient({
        host: config.host,
        accessToken: config.accessToken,
        space: `${config.spaceId}`,
      });

      client
        .getEntries(query)
        .then(({ items }) => head(items))
        .then((untypedEntry) => {
          if (!untypedEntry) throw new Error("No entries found for query.");
          return fromEntry(contentType)(untypedEntry);
        })
        .then(completed)
        .catch(failed)
        .then(setEntry);
    }, [query, config]);

    return (
      <WrappedComponent
        // NOTE: We're providing this hint to Typescript because it's having a hard time omitting
        // the RootNodeProps, but it _does_ typecheck correctly.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {...(props as OP)}
        entry={entry.type === "Completed" ? entry.value : undefined}
        error={entry.type === "Failed" ? entry.error : undefined}
      />
    );
  };

  return (props) =>
    isRootNodeProps(props) ? (
      <RootNode {...props} />
    ) : (
      <WrappedComponent {...props} />
    );
};

export default withContentful;
