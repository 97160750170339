import type { Designer } from "storefront/Algolia/Designer";
import { camelize } from "camelscore";
import { map, prop, compose, compact } from "lodash/fp";
import type { Id } from "storefront/lib/Id";
import "storefront/lib/Id";
import createInsecureAlgoliaClient from "storefront/Algolia/Client/createInsecureClient";
import { getAlgoliaIndexName } from "storefront/Config/PublicConfig/findAlgoliaIndexName";
import getConfig from "storefront/GrailedAPI/v1/Config/getConfig";

/**
 * Returns an array of designers using the Designer Index
 @param {Array<ObjectIds>} ids - an array of ids
 @return {Promise<Array<Designer>>}
*/
const fetchByIds = (ids: Array<Id>): Promise<Array<Designer>> => {
  const objectIdStrings = map(String, ids);
  return Promise.all([createInsecureAlgoliaClient(), getConfig()]).then(
    ([client, config]) => {
      const indexName = getAlgoliaIndexName("designers", "default")(config);
      const index = client.initIndex(indexName);
      return index
        .getObjects(objectIdStrings)
        .then(compose([camelize, compact, prop("results")]));
    },
  );
};

export default fetchByIds;
