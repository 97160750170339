import React from "react";
import Layout from "storefront/components/Page/Layout";
import useAuthentication from "storefront/hooks/useAuthentication";
import Homepage from "storefront/components/Homepage";
import LoadingHomepageContent from "storefront/components/Homepage/ContentfulHomepage/LoadingHomepageContent";

const Page = () => {
  const auth = useAuthentication();

  const authHasLoaded =
    auth.type === "Authenticated" ||
    auth.type === "Unauthenticated" ||
    auth.type === "Failed";

  return (
    <Layout>
      {authHasLoaded ? <Homepage auth={auth} /> : <LoadingHomepageContent />}
    </Layout>
  );
};

export default Page;
