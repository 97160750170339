import { Id, emptyId } from "storefront/lib/Id";

type ArticleContentValue = {
  label: string;
  src: string;
};

type ArticleContent = {
  name: string;
  type: string;
  value: string | Array<ArticleContentValue>;
};

export type Article = {
  id: Id;
  author: string;
  contentType: string;
  franchise: string;
  hero: string;
  heroLabel: string;
  metaDescription: string | null | undefined;
  publishedAt: string;
  slug: string;
  subtitle: string;
  tagList: Array<string>;
  title: string;
  url: string;
  content: Array<ArticleContent>;
};

export const loadingArticle: Article = {
  id: emptyId,
  author: "",
  content: [],
  contentType: "",
  franchise: "...",
  hero: "",
  heroLabel: "",
  metaDescription: "",
  publishedAt: "",
  slug: "",
  subtitle: "",
  tagList: [],
  title: "...",
  url: "",
};
