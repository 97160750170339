import React from "react";
import LazyLoad from "react-lazyload";
import cn from "classnames";
import ContentfulPicture from "storefront/components/ContentfulPicture";
import { FeedItemEntry } from "storefront/Contentful/types";
import styles from "./FilterItem.module.scss";

type Props = {
  entry: FeedItemEntry;
  handleClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
};

const FilterItem = ({ entry, handleClick, className }: Props) => {
  const { image, href } = entry.fields;

  return (
    <a className={cn(styles.item, className)} onClick={handleClick} href={href}>
      <LazyLoad height={132} once offset={200}>
        <ContentfulPicture
          images={[
            {
              device: "mobile",
              size: {
                h: 100,
                w: 100,
              },
              src: image.fields.file.url,
            },
            {
              device: "tablet",
              size: {
                h: 219,
                w: 219,
              },
              src: image.fields.file.url,
            },
            {
              device: "desktop",
              size: {
                h: 200,
                w: 200,
              },
              src: image.fields.file.url,
            },
          ]}
          alt={image.fields.title}
          className={styles.image}
        />
      </LazyLoad>
    </a>
  );
};

export default FilterItem;
