import React from "react";
import cn from "classnames";
import ReactMarkdown from "react-markdown";
import { TypedEntry } from "storefront/Contentful/TypedEntry";
import styles from "storefront/components/AlertBanner/AlertBanner.module.scss";

export const ALERT_BANNER = "moduleAlertBanner" as const;

export type AlertBannerModule = TypedEntry<
  typeof ALERT_BANNER,
  {
    name: string;
    message: string;
    alertLevel: "red" | "green" | "blue" | "yellow" | "black";
  }
>;

type Props = {
  entry: AlertBannerModule;
};

function AlertBanner(props: Props) {
  const { message, alertLevel } = props.entry.fields;

  return (
    <ReactMarkdown
      className={cn(styles.root, {
        [styles.red]: alertLevel.toLowerCase() === "red",
        [styles.blue]: alertLevel.toLowerCase() === "blue",
        [styles.yellow]: alertLevel.toLowerCase() === "yellow",
        [styles.green]: alertLevel.toLowerCase() === "green",
        [styles.black]: alertLevel.toLowerCase() === "black",
      })}
      source={message}
    />
  );
}

export default AlertBanner;
