import { Id } from "storefront/lib/Id";
import getCollectionsForUser, {
  PersonalizedCollections,
} from "storefront/GrailedAPI/v1/Collections/getCollectionsForUser";
import useData, { DataResource } from "storefront/hooks/data/useData";

export default function useCollectionsForUser(
  userId: Id | null | undefined,
): DataResource<PersonalizedCollections> {
  return useData(getCollectionsForUser, {
    args: userId ? [{ userId }] : undefined,
  });
}
