import classNames from "classnames";
import React from "react";
import Heading from "../Heading";
import FeaturedBlock from "./FeaturedBlock";
import FeaturedContentModuleEntry from "./FeaturedContentModuleEntry";
import css from "./FeaturedContent.module.scss";

type Props = {
  entry: FeaturedContentModuleEntry;
  from: string;
  position: number;
  className?: string;
};

const FeaturedContentModule = ({ entry, from, position, className }: Props) => (
  <section className={classNames(css.root, className)}>
    <Heading className={css.headline} title={entry.fields.headline} />

    <div className={css.blocks}>
      {entry.fields.featuredBlocks.map((item, index) => (
        <FeaturedBlock
          key={item.sys.id}
          entry={item}
          from={from}
          position={position}
          itemPosition={index}
          className={css.block}
        />
      ))}
    </div>
  </section>
);

export default FeaturedContentModule;
