import type { Article } from "storefront/Article";
import { get } from "storefront/GrailedAPI/request";

type Response = {
  data: Array<Article>;
};

const unpack = (res: Response): Array<Article> => res.data;

/**
 * @name GrailedAPI.v1.Articles.getFeaturedArticles
 * @return {Array<Article>}
 */
const getFeaturedArticles = (): Promise<Array<Article>> =>
  get<Response>(`/api/articles/featured`).then(unpack);

export default getFeaturedArticles;
