import React from "react";
import Image from "storefront/components/ImageV2";
import styles from "./DesignerItem.module.scss";

type Props = {
  name: string;
  slug: string;
  thumbnailUrl: string | null | undefined;
  trackClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

export const DesignerItem = ({
  name,
  slug,
  thumbnailUrl,
  trackClick,
}: Props) => {
  if (!thumbnailUrl) return <></>;

  return (
    <a
      href={`/designers/${slug}`}
      key={slug}
      onClick={trackClick}
      className={styles.container}
    >
      <Image
        className={styles.image}
        src={thumbnailUrl}
        alt={name}
        fill
        sizes="(min-width: 1200px) 185px, (min-width: 771px) calc(100vw / 6), 33vw"
      />
    </a>
  );
};
