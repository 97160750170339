import React, { useEffect } from "react";
import useAnalytics from "storefront/hooks/useAnalytics";
import FlashManager from "storefront/lib/flash/FlashManager";
import { requestWatcher } from "storefront/GrailedAPI/request/request";
import SiteBanner from "storefront/components/SiteBanner";
import SuspenseWithLoading from "storefront/components/SuspenseWithLoading";
import ViolationModal from "storefront/components/ViolationModal";
import {
  Authenticated,
  Unauthenticated,
  Failed,
} from "storefront/Authentication";
import useFeatureFlag from "storefront/hooks/data/useFeatureFlag";
import { Feature } from "storefront/GrailedAPI/v1/Users/Flipper/Feature";
import ContentfulHomepage from "./ContentfulHomepage";
import styles from "./index.module.scss";
import LoadingHomepageContent from "./ContentfulHomepage/LoadingHomepageContent";

type Props = {
  auth: Authenticated | Unauthenticated | Failed;
};

const Homepage = ({ auth }: Props) => {
  const { track } = useAnalytics();

  useEffect(() => {
    const flashManager = FlashManager.getInstance();
    const unsubscribe = requestWatcher.subscribe((requestKey) => {
      flashManager.notice(`${requestKey}:  Too many requests!`);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    track({
      object: "homepage",
      action: "viewed",
      properties: {
        nonInteraction: 1,
      },
    });
  }, [track]);

  const newHomepageFlag =
    auth.type === "Authenticated"
      ? Feature.NEW_HOMEPAGE_LOGGED_IN
      : Feature.NEW_HOMEPAGE_LOGGED_OUT;

  const newHomepageEnabledResource = useFeatureFlag(newHomepageFlag);

  if (newHomepageEnabledResource.type !== "Completed")
    return <LoadingHomepageContent />;

  return (
    <div id="homepage-v2">
      <div id="siteBanner">
        <SiteBanner />
      </div>

      <ViolationModal />

      <div className="homepage-wrapper">
        <SuspenseWithLoading className={styles.loading}>
          <ContentfulHomepage
            contentfulEntryName={
              newHomepageEnabledResource.value ? "homepageVariant" : "homepage"
            }
          />
        </SuspenseWithLoading>
      </div>
    </div>
  );
};

export default Homepage;
