import React from "react";
import { Icon } from "storefront/Icon";

type Props = Icon & {
  fill?: string;
};

const HalfStar = ({
  width = "22px",
  height = "22px",
  fill = "black",
  className,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9375 0.5L14 7.4375H21.3125L21.875 9.125L16.0625 13.4375L18.3125 20.375L16.8125 21.5L11 17.1875L5.1875 21.5L3.6875 20.375L5.9375 13.4375L0.125 9.125L0.6875 7.4375H8L10.0625 0.5H11.9375ZM11 15.3197L16.394 19.3217L14.3032 12.875L19.6103 8.9375H12.8811L11 2.61039L11 15.3197Z"
      fill={fill}
    />
  </svg>
);

export default HalfStar;
