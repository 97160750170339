import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { map, pipe, flatMap, prop, filter } from "lodash/fp";
import { getId } from "storefront/Contentful/Entry";
import useMediaQuery, { MOBILE } from "storefront/hooks/useMediaQuery";
import BackgroundImage from "storefront/components/Image/BackgroundImage";
import GuideCardModule from "storefront/components/Modules/Guide/GuideCardModule";
import ContentfulPageWrapper from "storefront/components/Modules/ContentfulPageWrapper";
import {
  GuideScreenModule as GuideScreenModuleType,
  GuideCardModule as GuideCardModuleType,
  GuideScreen as GuideScreenType,
  LaunchItem as LaunchItemType,
} from "storefront/components/Modules/Guide/types";
import styles from "./HomeGuideScreen.module.scss";

type Props = {
  entry: GuideScreenType;
};

// CONSTANT VARIABLES AND FUNCTIONS
// @ts-expect-error ts-migrate(2339) FIXME: Property 'convert' does not exist on type 'LodashM... Remove this comment to see the full error message
const mapWithIndex = map.convert({
  cap: false,
});
const BASE_ROUTE = "/how-to-sell";

const HeroImage = ({ heroUrl }: { heroUrl: string }) => (
  <div className={styles.heroImage}>
    <BackgroundImage
      TagName="div"
      className={styles.image}
      sources={{ desktop: { url: heroUrl } }}
    />
  </div>
);

const GuideCardModuleListItem = ({
  guideCardModule,
}: {
  guideCardModule: GuideCardModuleType;
}) => (
  <li className={styles.guideCardModuleListItem} key={getId(guideCardModule)}>
    <GuideCardModule key={getId(guideCardModule)} entry={guideCardModule} />
  </li>
);

const SidebarOrBackButton = ({
  modules,
}: {
  modules: [GuideScreenModuleType];
}) => {
  const isMobile = useMediaQuery(MOBILE);
  const location = useLocation();

  if (isMobile && location.pathname !== BASE_ROUTE) {
    return (
      <div className={styles.backButton}>
        <Link to={BASE_ROUTE}>← How to Sell Guide</Link>
      </div>
    );
  }

  return (
    <div className={styles.sidebar}>
      <ul className={styles.guideCardModuleList}>
        {pipe([
          filter({
            contentType: "guideCardModule",
          }),
          map((guideCardModule: GuideCardModuleType) => (
            <GuideCardModuleListItem
              guideCardModule={guideCardModule}
              key={getId(guideCardModule)}
            />
          )),
        ])(modules)}
      </ul>
    </div>
  );
};

const LaunchItemRoute = ({
  launchItem,
  index,
}: {
  launchItem: LaunchItemType;
  index: number;
}) => {
  const { url, pageEntryId, title } = launchItem.fields;
  const baseClassName = `HowToSellGuide--${title}`.replace(/\s/g, "");

  const component = () => (
    <ContentfulPageWrapper
      baseClassName={baseClassName}
      id={pageEntryId}
      from={url}
    />
  );

  let homeRoute = null;
  const isMobile = useMediaQuery(MOBILE);

  if (!isMobile && index === 0) {
    homeRoute = (
      <Route key={index} path={BASE_ROUTE} exact component={component} />
    );
  }

  return (
    <Fragment key={getId(launchItem)}>
      {homeRoute}
      <Route
        key={getId(launchItem)}
        path={url}
        exact={false}
        component={component}
      />
    </Fragment>
  );
};

const LaunchItemRoutes = ({
  modules,
}: {
  modules: [GuideScreenModuleType];
}) => {
  return (
    <div className={styles.routeWrapper}>
      {pipe([
        filter({
          contentType: "guideCardModule",
        }),
        flatMap(prop("fields.launchItems")),
        mapWithIndex((launchItem: LaunchItemType, index: number) => (
          <LaunchItemRoute
            launchItem={launchItem}
            index={index}
            key={getId(launchItem)}
          />
        )),
      ])(modules)}
    </div>
  );
};

const GuideNavigation = ({ modules }: { modules: [GuideScreenModuleType] }) => (
  <Router>
    <div className={styles.routerWrapper}>
      <SidebarOrBackButton modules={modules} />
      <LaunchItemRoutes modules={modules} />
    </div>
  </Router>
);

const HomeGuideScreen = ({ entry }: Props) => {
  const { title, description, heroUrl, modules } = entry.fields;
  return (
    <div className={styles.homeGuideScreen}>
      <h1 className={styles.title}>{title}</h1>
      <h2 className={styles.description}>{description}</h2>
      {heroUrl ? <HeroImage heroUrl={heroUrl} /> : null}
      <GuideNavigation modules={modules} />
    </div>
  );
};

export default HomeGuideScreen;
