import { TypedEntry } from "storefront/Contentful/TypedEntry";
import {
  ContentLaunchItem,
  FrequentlyAskedQuestionModule,
  ContentLaunchItemCarousel,
  TestimonialCarousel,
} from "storefront/Contentful/types";

/**
 * @description This file describes the modeling for the
 * How to Sell Guide. These types should map to the Contentful
 * models exactly.
 */

export const GUIDE_SCREEN = "guideScreen" as const;

export type LaunchItem = TypedEntry<
  "launchItem", // ContentfulTypeId
  {
    title: string;
    link: string;
    url: string;
    pageEntryId: string;
  }
>;

export type GuideCardModule = TypedEntry<
  "guideCardModule",
  {
    title: string;
    description: string;
    launchItems: [LaunchItem];
  }
>;

export type ImageItem = TypedEntry<
  "imageItem",
  {
    imageUrl: string;
    launchItem?: LaunchItem;
  }
>;

export type GuideImageModule = TypedEntry<
  "guideImageModule",
  {
    imageItems: [ImageItem];
  }
>;

export type TitleDescriptionItem = TypedEntry<
  "titleDescriptionItem",
  {
    title: string;
    description: string;
  }
>;

export type GuideCardTextModule = TypedEntry<
  "guideCardTextModule",
  {
    titleDescriptionItems: [TitleDescriptionItem];
  }
>;

export type GuideTextModule = TypedEntry<
  "guideTextModule",
  {
    description: string;
  }
>;

export type GuideSingleImageModule = TypedEntry<
  "guideSingleImageModule",
  {
    imageItem: ImageItem;
  }
>;

export type GuideSectionWrapperModule =
  | GuideTextModule
  | GuideSingleImageModule
  | TitleDescriptionItem
  | ContentLaunchItem
  | FrequentlyAskedQuestionModule;

export type GuideSectionWrapper = TypedEntry<
  "sectionWrapper",
  {
    title: string;
    description: string;
    modules: [GuideSectionWrapperModule];
  }
>;

export type GuideScreenModule =
  | GuideCardModule
  | GuideImageModule
  | GuideCardTextModule
  | GuideTextModule
  | GuideSingleImageModule
  | ContentLaunchItemCarousel
  | GuideSectionWrapper
  | TestimonialCarousel;

export type GuideScreen = TypedEntry<
  typeof GUIDE_SCREEN,
  {
    title: string;
    description: string;
    heroUrl?: string;
    modules: [GuideScreenModule];
  }
>;
