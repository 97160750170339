import React from "react";
import RatingStars from "storefront/components/Feedback/RatingStars";
import type { UserTestimonial as UserTestimonialType } from "../../../Contentful/types";

type Props = {
  entry: UserTestimonialType;
};

/**
 * @name Modules.UserTestimonial
 * @description Renders a user testimonial on a trust microsite page
 * @param {Props} props
 * @returns {React$Element<*>}
 */
const UserTestimonial = ({ entry }: Props) => {
  const { name, reviewFrom, headline, quote, rating } = entry.fields;
  return (
    <div className="Module--UserTestimonial">
      <h2 className="--headline">{headline}</h2>
      <p className="--quote">&quot;{quote}&quot;</p>
      <p className="--author">{name}</p>
      <RatingStars value={rating} />
      <p className="--source">Review from {reviewFrom}</p>
    </div>
  );
};

export default UserTestimonial;
