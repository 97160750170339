import type { Id } from "storefront/lib/Id";
import type { PageType } from "storefront/Analytics/Event";

export type DesignerFollowProperties = {
  designers: Array<Id>;
  designerNames: Array<string>;
  isFollow: boolean;
  from: string;
  pageType: PageType;
  pageTypeName?: string;
  pageTypeIdentifier?: Id;
  userId: Id;
};

export type DesignerFollowEvent = {
  object: "designer";
  action: "followed" | "unfollowed";
  properties: {
    designers: Array<Id>;
    designerNames: Array<string>;
    from: string;
    pageType: PageType;
    pageTypeName: string | null | undefined;
    pageTypeIdentifier: Id | null | undefined;
    userId: Id;
  };
};

/**
 * @description Creates an event that can be given to `track` to record Designer Follow and Designer Unfollow
 */
const toggleDesignerFollow = ({
  isFollow,
  designers,
  designerNames,
  from,
  pageType,
  pageTypeName,
  pageTypeIdentifier,
  userId,
}: DesignerFollowProperties): DesignerFollowEvent => ({
  object: "designer",
  action: isFollow ? "followed" : "unfollowed",
  properties: {
    designers,
    designerNames,
    from,
    pageType,
    pageTypeName,
    pageTypeIdentifier,
    userId,
  },
});

export default toggleDesignerFollow;
