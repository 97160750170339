import React from "react";
import classNames from "classnames";
import { Id } from "storefront/lib/Id";
import { Resource } from "storefront/lib/Resource";
import getHumanReadableNumber from "storefront/lib/getHumanReadableNumber";
import Image from "storefront/components/ImageV2";
import FollowButton from "../FollowButton";
import Footnote from "../Footnote";
import Subhead from "../Subhead";

import styles from "./DesignerCard.module.scss";

const MAX_PHOTOS = 2;

type Props = {
  id: Id;
  name: string;
  slug: string;
  listingsCount: number;
  isFollowing: Resource<boolean>;
  onClickFollow: (id: Id) => void;
  photoUrls: Array<string>;
  trackClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  cardClassName?: string;
  contentClassName?: string;
};

const getListingCountCopy = (count: number) =>
  count === 1 ? "1 listing" : `${getHumanReadableNumber(count)} listings`;

const DesignerCard = ({
  name,
  slug,
  listingsCount,
  id,
  isFollowing,
  onClickFollow,
  photoUrls,
  trackClick,
  cardClassName = "",
  contentClassName = "",
}: Props) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClickFollow(id);
  };

  const isDetailed = photoUrls.length > 0 && listingsCount > 0;
  const photoSlice = photoUrls.slice(0, MAX_PHOTOS);

  return (
    <a
      className={classNames(styles.designerCard, cardClassName)}
      href={`/designers/${slug}`}
      onClick={trackClick}
    >
      <div className={classNames(styles.contentContainer, contentClassName)}>
        {isDetailed ? (
          <div className={styles.photos}>
            {photoSlice.map((url, index) => (
              <div>
                <Image
                  key={url}
                  src={url}
                  alt={`${name} photo ${index + 1}`}
                  fill
                  sizes="(min-width: 1200px) 15vw, 20vw"
                  fit="crop"
                />
              </div>
            ))}
          </div>
        ) : null}
        <Subhead
          className={classNames(styles.name, {
            [styles.centered]: !isDetailed,
          })}
        >
          {name}
        </Subhead>
        {isDetailed ? (
          <Footnote as="p">{getListingCountCopy(listingsCount)}</Footnote>
        ) : null}
        <div className={styles.followButton}>
          <FollowButton
            following={
              isFollowing.type === "Completed" ? isFollowing.value : false
            }
            onClick={onClick}
            loading={isFollowing.type === "Loading"}
          />
        </div>
      </div>
    </a>
  );
};

export default DesignerCard;
