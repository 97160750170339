import classNames from "classnames";
import React from "react";
import Button from "storefront/components/Button";
import useAnalytics from "storefront/hooks/useAnalytics";
import moduleClicked from "storefront/Analytics/EventCreators/moduleClicked";
import useCallbackOnView from "storefront/hooks/useCallbackOnView";
import BackgroundImage from "storefront/components/Image/BackgroundImage";
import SmallTitle from "storefront/components/SmallTitle";
import FeaturedBlockEntry from "./FeaturedBlockEntry";
import css from "./FeaturedBlock.module.scss";

export type FeatureBlockViewedEvent = {
  object: "Featured Block";
  action: "viewed";
  properties: {
    banner_name: string;
    banner_position: number;
  };
};

type Props = {
  entry: FeaturedBlockEntry;
  from: string;
  position: number;
  itemPosition: number;
  className?: string;
};

const FeaturedBlock = ({
  entry,
  from,
  position,
  itemPosition,
  className,
}: Props) => {
  const {
    backgroundImage,
    mobileImage,
    ctaButtonLink,
    ctaText,
    marketingSubtitle,
    marketingTitle,
    name,
  } = entry.fields;
  const { track } = useAnalytics();
  const ref = useCallbackOnView(
    () => {
      track({
        object: "Featured Block",
        action: "viewed",
        properties: {
          banner_name: name,
          banner_position: itemPosition + 1,
        },
      });
    },
    false,
    0.5,
  );

  const trackClick = () => {
    track(
      moduleClicked({
        moduleType: "Featured Block",
        moduleName: marketingSubtitle,
        moduleNameContentful: name,
        modulePosition: position,
        itemType: "CTA",
        itemPosition,
        itemName: marketingTitle,
        itemNameContentful: marketingTitle,
        imageUrl: backgroundImage.fields.file.url,
        from,
      }),
    );
  };

  return (
    <a
      className={classNames(css.root, className)}
      ref={ref}
      href={ctaButtonLink}
      onClick={trackClick}
    >
      <BackgroundImage
        TagName="h3"
        className={css.title}
        sources={{
          desktop: {
            url: backgroundImage.fields.file.url,
            params: { height: 1000 },
          },
          mobile: {
            url: mobileImage.fields.file.url,
            params: { height: 600 },
          },
        }}
      >
        {marketingTitle}
      </BackgroundImage>

      <div className={css.details}>
        <SmallTitle className={css.subtitle}>{marketingSubtitle}</SmallTitle>

        <Button className={css.button} variant="secondary" size="small">
          {ctaText}
        </Button>
      </div>
    </a>
  );
};

export default FeaturedBlock;
