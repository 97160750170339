import { stringify } from "qs";
import type { ContentfulImage } from ".";
import type { Density } from "../../lib/Density";

/**
 * @name src
 * @memberof Contentful.Image
 * @description This function encapsulates the logic needed to build a valid Contentful image source URL. Specifically, we need the option params set at the correct keys (w, h, fm, etc.).
 *
 * @param {Density} density
 * @param {Image} image
 * @returns {string} a valid Contentful image source URL.
 */
const src = (
  { url, format, width, height, fit }: ContentfulImage,
  density: Density = 1,
): string => {
  const options = {};
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'fm' does not exist on type '{}'.
  if (format) options.fm = format;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'h' does not exist on type '{}'.
  if (height) options.h = height * density;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'w' does not exist on type '{}'.
  if (width) options.w = width * density;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'fit' does not exist on type '{}'.
  if (fit) options.fit = fit;
  const params = stringify(options);
  return `${url}?${params}`;
};

export default src;
