import React from "react";
import { map } from "lodash/fp";
import { getId } from "../../../Contentful/Entry";
import TitleDescriptionItem from "./TitleDescriptionItem";
import type {
  GuideCardTextModule as GuideCardTextModuleType,
  TitleDescriptionItem as TitleDescriptionItemType,
} from "./types";

type Props = {
  entry: GuideCardTextModuleType;
};

const renderTitleDescriptionItem: (
  arg0: TitleDescriptionItemType,
) => React.ReactElement<React.ComponentProps<any>, any> = (
  titleDescriptionItem,
) => (
  <TitleDescriptionItem
    key={getId(titleDescriptionItem)}
    entry={titleDescriptionItem}
  />
);

const GuideCardTextModule: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({ entry }) => {
  const { titleDescriptionItems } = entry.fields;
  return (
    <div className="Module--GuideCardText">
      {map(renderTitleDescriptionItem, titleDescriptionItems)}
    </div>
  );
};

export default GuideCardTextModule;
