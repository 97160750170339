import React from "react";
import { getId } from "storefront/Contentful/Entry";
import { GuideScreen as GuideScreenType } from "storefront/components/Modules/Guide/types";
import HomeGuideScreen from "./GuideScreen/HomeGuideScreen";
import ContentGuideScreen from "./GuideScreen/ContentGuideScreen";

type Props = {
  entry: GuideScreenType;
};

const isHomeGuideScreen = (entry: GuideScreenType): boolean =>
  getId(entry) === "5vuj1VbWvoJpg0Cw1gjAaW" ||
  getId(entry) === "7xg0gter8jBykYo0Atm2Qi";

const GuideScreen = ({ entry }: Props) => {
  if (isHomeGuideScreen(entry)) {
    return <HomeGuideScreen entry={entry} />;
  }

  return <ContentGuideScreen entry={entry} />;
};

export default GuideScreen;
