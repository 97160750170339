import React from "react";
import { Icon } from "storefront/Icon";

type Props = Icon & {
  fill?: string;
};

const StarOutline = ({
  width = "22px",
  height = "22px",
  fill = "black",
  className,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 7.4375L11.9375 0.5H10.0625L8 7.4375H0.6875L0.125 9.125L5.9375 13.4375L3.6875 20.375L5.1875 21.5L11 17.1875L16.8125 21.5L18.3125 20.375L16.0625 13.4375L21.875 9.125L21.3125 7.4375H14ZM19.6103 8.9375H12.8811L11 2.6103L9.11894 8.9375H2.38971L7.69684 12.875L5.60602 19.3217L11 15.3197L16.394 19.3217L14.3032 12.875L19.6103 8.9375Z"
      fill={fill}
    />
  </svg>
);

export default StarOutline;
