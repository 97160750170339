import React from "react";
import {
  Carousel,
  CarouselContent,
  CarouselNavDots,
  CarouselNavNext,
  CarouselNavPrev,
  CarouselSlide,
} from "storefront/components/CarouselNext";
import { TypedEntry } from "storefront/Contentful/TypedEntry";
import BigVideoModule, {
  BIG_VIDEO,
  BigVideoModuleEntry,
} from "../BigVideoModule";
import HeroCarouselContentItem, {
  HERO_CAROUSEL_CONTENT_ITEM,
  HeroCarouselContentItemEntry,
} from "../HeroCarouselContentItem";

import styles from "./HeroCarousel.module.scss";

export const HERO_CAROUSEL = "searchCtaSuggestion" as const;

export type HeroCarouselEntry = TypedEntry<
  typeof HERO_CAROUSEL,
  {
    name: string;
    autoScroll: boolean;
    contentItems: Array<BigVideoModuleEntry | HeroCarouselContentItemEntry>;
  }
>;

type SlideComponentProps = {
  entry: BigVideoModuleEntry | HeroCarouselContentItemEntry;
  from: string;
  position: number;
};

const SlideComponent = ({ entry, from, position }: SlideComponentProps) => {
  const { contentType } = entry;

  switch (contentType) {
    case HERO_CAROUSEL_CONTENT_ITEM:
      return (
        <CarouselSlide>
          <HeroCarouselContentItem entry={entry} />
        </CarouselSlide>
      );
    case BIG_VIDEO:
      return (
        <CarouselSlide className={styles.slide}>
          <BigVideoModule
            entry={entry}
            from={from}
            position={position}
            className={styles.videoModule}
          />
        </CarouselSlide>
      );
    default:
      ((_: never): void => {})(contentType);
      return null;
  }
};

type Props = {
  entry: HeroCarouselEntry;
  from: string;
  position: number;
};

const HeroCarousel = ({ entry, from, position }: Props) => {
  const slides = entry.fields.contentItems;
  const { autoScroll } = entry.fields;

  return (
    <Carousel autoscroll={autoScroll} loop>
      <CarouselContent>
        {slides.map((slide) => {
          return (
            <SlideComponent
              entry={slide}
              from={from}
              position={position}
              key={entry.contentType + slide.sys.id}
            />
          );
        })}
      </CarouselContent>
      {slides.length > 1 ? (
        <>
          <CarouselNavNext />
          <CarouselNavPrev />
          <CarouselNavDots />
        </>
      ) : null}
    </Carousel>
  );
};

export default HeroCarousel;
