import React from "react";
import { map } from "lodash/fp";
import { getId } from "../../../Contentful/Entry";
import LaunchItem from "./LaunchItem";
import type {
  LaunchItem as LaunchItemType,
  GuideCardModule as GuideCardModuleType,
} from "./types";

type Props = {
  entry: GuideCardModuleType;
};

const renderLaunchItemListItem = (launchItem: LaunchItemType) => (
  <li className="-launch-item-list-item" key={getId(launchItem)}>
    <LaunchItem key={getId(launchItem)} entry={launchItem} />
  </li>
);

const GuideCardModule = ({ entry }: Props) => {
  const { title, description, launchItems } = entry.fields;
  return (
    <div className="Module--GuideCard">
      <h2 className="-title">{title}</h2>
      <p className="-description">{description}</p>
      <ul className="-launch-item-list">
        {map((launchItem) => renderLaunchItemListItem(launchItem), launchItems)}
      </ul>
    </div>
  );
};

export default GuideCardModule;
