import React from "react";
import Picture from "storefront/components/Picture";
import { toContentfulImage } from "./ContentfulImageParams";
import type { ContentfulImageParams } from "./ContentfulImageParams";

/**
 * @name ContentfulPicture
 * @description
 *  returns a <img /> element which conditionally uses the correct
 *  src and srcSet based on device and webp image support. Note this is called a
 *  Picture because it behaves like a <picture> (renders correct asset based on media queries).
 *  Passes down all extra props (like style).
 *  Promoting Image optimization.
 *
 *  Requires images array and alt tag.
 * @param {Props} props
 * @returns {React$Element<*>}
 */
type Props = {
  images: Array<ContentfulImageParams>;
  format?: string;
  alt: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const ContentfulPicture = ({ images, format, alt, ...rest }: Props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Picture {...rest} alt={alt} images={images.map(toContentfulImage(format))} />
);

export default ContentfulPicture;
