/* eslint-disable max-classes-per-file */

import { Entry, Asset } from "contentful";
import { mapValues } from "lodash/fp";
import { isEntry } from "./Entry";

export type TypedEntry<ContentType extends string, Fields> = {
  sys: Entry<unknown>["sys"];
  fields: Fields;
  contentType: ContentType;
};

export const isTypedEntry =
  <T extends TypedEntry<string, unknown>>(contentType: T["contentType"]) =>
  (entry: TypedEntry<string, unknown>): entry is T =>
    entry.contentType === contentType;

export class ContentTypeMismatch extends Error {
  constructor(typeA: string, typeB: string) {
    super(`ContentTypeMismatch: ${typeA} does not match ${typeB}.`);
  }
}

type ProcessedField =
  | string
  | number
  | boolean
  | TypedEntry<string, unknown>
  | Asset
  | Array<ProcessedField>;

export const fromEntry =
  <T extends TypedEntry<string, unknown>>(contentType: T["contentType"]) =>
  (entry: Entry<any>): T => {
    const processField = (value: any): ProcessedField => {
      if (isEntry<any>(value))
        return fromEntry(value.sys.contentType.sys.id)(value);

      if (value instanceof Array) return value.map(processField);

      return value;
    };

    const fields = mapValues(processField)(entry.fields);

    const typedEntry = {
      sys: entry.sys,
      contentType: entry.sys.contentType.sys.id,
      fields,
    };

    if (!isTypedEntry(contentType)(typedEntry))
      throw new ContentTypeMismatch(contentType, typedEntry.contentType);

    return typedEntry;
  };
