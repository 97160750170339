import React from "react";
import ContentfulPageWrapper from "storefront/components/Modules/ContentfulPageWrapper";
import usePublicConfig from "storefront/hooks/usePublicConfig";

type Props = {
  contentfulEntryName: string;
};

const ContentfulHomepage = ({ contentfulEntryName }: Props) => {
  const { contentful } = usePublicConfig();

  const entryIds = contentful.spaces.grailed?.entryIds;

  const contentfulId = entryIds?.[contentfulEntryName];

  if (!contentfulId || typeof contentfulId !== "string") return null;

  return (
    <div className="Homepage--Merchandising">
      <ContentfulPageWrapper
        baseClassName="Homepage"
        id={contentfulId}
        from="homepage"
        pageType="homepage"
      />
    </div>
  );
};

export default ContentfulHomepage;
