import classNames from "classnames";
import React, { MouseEventHandler } from "react";
import Image from "storefront/components/ImageV2";
import { TrackingItemProperties } from "storefront/Contentful/types";
import { CategoryDetailPage } from "storefront/DetailPage";
import css from "./CategoryItem.module.scss";

type Props = {
  position: number;
  page: CategoryDetailPage;
  trackClick: (
    properties: TrackingItemProperties,
  ) => MouseEventHandler<HTMLAnchorElement>;
  className?: string;
};

const buildTrackingProperties = (
  page: CategoryDetailPage,
  position: number,
): TrackingItemProperties => ({
  itemType: "category",
  itemPosition: position,
  itemName: page.slug,
  itemNameContentful: page.slug,
  imageUrl: page.thumbnailUrl || "",
});

const categoryPathBySlug = (slug: string) =>
  slug.startsWith("womenswear") ? slug.replace("-", "/") : slug;

const CategoryItem = ({ page, position, trackClick, className }: Props) => (
  <a
    key={page.slug}
    className={classNames(css.root, className)}
    href={`/categories/${categoryPathBySlug(page.slug)}`}
    onClick={trackClick(buildTrackingProperties(page, position))}
  >
    <Image
      className={css.image}
      src={page.thumbnailUrl || ""}
      alt={`${page.title || ""} Category`}
      fill
      data-testid="category-item-image"
      sizes="(min-width: 1200px) 180px, (min-width: 771px) calc(100vw / 6), 33vw"
    />

    <p className={css.title}>{page.title}</p>
  </a>
);

export default CategoryItem;
