import React from "react";
import classnames from "classnames";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
import { Article } from "storefront/Article";
import articleClicked from "storefront/Analytics/EventCreators/articleClicked";
import useMediaQuery from "storefront/hooks/useMediaQuery";
import useAnalytics from "storefront/hooks/useAnalytics";
import BackgroundImage from "storefront/components/Image/BackgroundImage";
import styles from "./ArticleItem.module.scss";

type Props = {
  article: Article;
  isAlgoliaArticle?: boolean;
  from?: string;
  additionalTrack?: () => void;
};

const ArticleItem = ({
  article,
  isAlgoliaArticle,
  from,
  additionalTrack,
}: Props) => {
  const { t } = useTranslation("common");
  const { track } = useAnalytics();

  const trackClick = () => {
    if (additionalTrack) additionalTrack();

    // NOTE: This Article Clicked event doesn't seem to be getting tracked.
    // Analytics has been aware that this event is not working correctly,
    // but has deemed it low priority to fix.
    track(articleClicked(article, from || "homepage"));
  };

  const { hero, franchise, title, url } = article;

  const width = useMediaQuery("MOBILE") ? 240 : 450;
  const height = useMediaQuery("MOBILE") ? 160 : 300;

  const articleUrl = isAlgoliaArticle
    ? `${t("BLOG_LINK_PATH")}/${article.slug}`
    : url;

  return (
    <div className={styles.article}>
      <a href={articleUrl} onClick={trackClick} title={title}>
        <LazyLoad height={height || 190} once offset={200}>
          <BackgroundImage
            TagName="div"
            className={classnames(styles.image, {
              _loading: hero === "",
            })}
            sources={{
              desktop: {
                url: hero,
                params: {
                  width,
                  height,
                  quality: 70,
                },
              },
            }}
          />
        </LazyLoad>
      </a>

      <div className={styles.content}>
        <h3 className={styles.franchise}>{franchise}</h3>
        <h3 className={styles.title}>{title}</h3>
      </div>
    </div>
  );
};

export default ArticleItem;
