import { get } from "storefront/GrailedAPI/request";
import { Options } from "storefront/GrailedAPI/request/Options";
import { Id } from "storefront/lib/Id";
import { CollectionDisplay } from "storefront/Collection";

export type PersonalizedCollections = {
  collections: Array<CollectionDisplay>;
  algorithmId: string;
};

type Params = {
  userId?: Id;
};

type Response = {
  data: PersonalizedCollections;
};

const unpack = (res: Response): PersonalizedCollections => res.data;

export default function getCollectionsForUser(
  params: Params = {},
  options?: Options,
): Promise<PersonalizedCollections> {
  return get<Response>("/api/collections", params, options).then(unpack);
}
