import React from "react";

type Props = {
  children: React.ReactElement<React.ComponentProps<any>, any>;
};
type State = {
  hasError: boolean;
};
export default class ContentfulErrorBoundary extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
  componentDidCatch(error, info) {
    console.error(error, info);
  }

  // eslint-disable-line
  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}
