import React from "react";
import useAnalytics from "storefront/hooks/useAnalytics";
import moduleClicked from "storefront/Analytics/EventCreators/moduleClicked";
import Carousel from "storefront/components/Carousel";
import {
  FeedItemEntry,
  TrackingItemProperties,
} from "storefront/Contentful/types";
import { TypedEntry } from "storefront/Contentful/TypedEntry";
import Heading from "../Heading";
import FeedItem from "../FeedGrid/FeedItem";
import styles from "./FeedCarousel.module.scss";

export const FEED_CAROUSEL = "moduleFeedCarousel" as const;

export type FeedCarouselModule = TypedEntry<
  typeof FEED_CAROUSEL,
  {
    feedItems: Array<FeedItemEntry>;
    name: string;
    title: string;
  }
>;

type Props = {
  entry: FeedCarouselModule;
  position: number;
  from: string;
};

const FeedCarousel = ({ entry, position, from }: Props) => {
  const { name, title, feedItems } = entry.fields;
  const { track } = useAnalytics();

  const trackClick =
    (properties: TrackingItemProperties) =>
    (_event: React.MouseEvent<HTMLAnchorElement>): void => {
      track(
        moduleClicked({
          ...properties,
          from,
          moduleType: "Feed Carousel",
          moduleName: title,
          moduleNameContentful: name,
          modulePosition: position,
        }),
      );
    };

  return (
    <div className={styles.module}>
      <Heading title={title} />
      <Carousel
        baseClassName={styles.carouselItem}
        itemsToShow={3}
        carouselItems={feedItems.map((item, index) => (
          <FeedItem
            entry={item}
            href={`/shop/${item.fields.feedId}`}
            index={index}
            key={item.sys.id}
            trackClick={trackClick}
            className={styles.feedItem}
          />
        ))}
      />
    </div>
  );
};

export default FeedCarousel;
