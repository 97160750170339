import numeral from "numeral";

/**
 * Returns a number in shortened format. Ignores any decimals by parsing to
 * integer first.
 *
 * Numbers between -1000 and 1000 return in full form.
 * Numbers greater than 1000 or less than -1000 return with precision of 1 by
 * the abbreviation for 1000 increments (k, m, b, t). If the precision is not
 * needed, then it will not display.
 *
 * Example: 523.123 -> "523"
 * Example: 12345 -> "12.3k"
 * Example: 16000 -> "16k"
 * Example: 12000.5 -> "12k"
 * Example: 1234567.123 -> "1.2m"
 */
const getHumanReadableNumber = (num: number): string => {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  const integer = parseInt(num, 10);

  if (integer < 1000 && integer > -1000) {
    return `${integer}`;
  }

  return numeral(integer).format("0.[0]a");
};

export default getHumanReadableNumber;
