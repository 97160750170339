import React from "react";
import ReactMarkdown from "react-markdown";
import { Paragraph, Link } from "../../../MarkdownComponents";
import type { GuideTextModule as GuideTextModuleType } from "./types";

type Props = {
  entry: GuideTextModuleType;
};

const GuideTextModule: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({ entry }) => {
  const { description } = entry.fields;
  return (
    <ReactMarkdown
      className="Module--GuideText"
      source={description}
      renderers={{
        paragraph: Paragraph("-description"),
        link: Link("-link"),
      }}
      linkTarget="_blank"
    />
  );
};

export default GuideTextModule;
