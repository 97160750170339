import React from "react";
import cn from "classnames";
import { TEXT_CLASS_NAME } from "../Text";
import styles from "./Title1.module.scss";

type Props = {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
} & React.HTMLAttributes<HTMLOrSVGElement>;

const Title1 = ({ as: As = "p", className, ...props }: Props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <As {...props} className={cn(TEXT_CLASS_NAME, styles.title1, className)} />
);

export default Title1;
