import { DENSITIES } from "../../lib/Density";
import type { Density } from "../../lib/Density";
import type { ContentfulImage } from "./index";
import src from "./src";

const buildPart =
  (image: ContentfulImage) =>
  (density: Density): string =>
    `${src(image, density)} ${density}x`;

const srcSet = (image: ContentfulImage): string =>
  DENSITIES.map(buildPart(image)).join(", ");

export default srcSet;
