import React from "react";
import { Id } from "storefront/lib/Id";
import { Resource } from "storefront/lib/Resource";
import Carousel from "storefront/components/Carousel";
import DesignerCard from "storefront/components/DesignerCard";
import styles from "./index.module.scss";

export type CarouselDesigner = {
  id: Id;
  name: string;
  slug: string;
  listingsCount: number;
  photoUrls: Array<string>;
  isFollowing: Resource<boolean>;
};

type Props = {
  carouselDesigners: Array<CarouselDesigner>;
  numOfItemsToShow: number;
  onClickFollow: (id: Id) => void;
  trackClick: (
    designer: CarouselDesigner,
    index: number,
  ) => (e: React.SyntheticEvent<any>) => void;
};

const DesignerCarousel = ({
  carouselDesigners,
  numOfItemsToShow,
  onClickFollow,
  trackClick,
}: Props) => (
  <Carousel
    containerClassName={styles.designerCarousel}
    baseClassName={styles.carouselItem}
    itemsToShow={numOfItemsToShow}
    carouselItems={carouselDesigners.map((data, index) => (
      <DesignerCard
        key={data.id}
        id={data.id}
        name={data.name}
        slug={data.slug}
        listingsCount={data.listingsCount}
        isFollowing={data.isFollowing}
        onClickFollow={onClickFollow}
        photoUrls={data.photoUrls}
        trackClick={trackClick(data, index)}
        contentClassName={styles.cardContent}
      />
    ))}
  />
);

export default DesignerCarousel;
