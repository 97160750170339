import React from "react";
import Button from "storefront/components/Button";

export type Props = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => unknown;

  /** Determines the button text and variant */
  following: boolean;

  /** Will display a loading animation. This disables the onClick prop */
  loading: boolean;

  /** Extend this component's styles with additional CSS classes */
  className?: string;
} & React.HTMLAttributes<HTMLButtonElement>;

/**
 * The unify following button.
 */
const FollowButton = ({ following, onClick, loading, ...props }: Props) => (
  <Button
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
    size="small"
    variant={following ? "secondary" : "primary"}
    loading={loading}
    onClick={!loading ? onClick : undefined}
  >
    {following ? "Following" : "Follow"}
  </Button>
);

export default FollowButton;
