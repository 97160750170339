import React from "react";
import ContentfulPicture from "storefront/components/ContentfulPicture";
import type { PressTestimonial as PressTestimonialType } from "../../../Contentful/types";

type Props = {
  entry: PressTestimonialType;
};

/**
 * @name Modules.PressTestimonial
 * @description Renders a user testimonial on a trust microsite page
 * @param {Props} props
 * @returns {React$Element<*>}
 */
const PressTestimonial = ({ entry }: Props) => {
  const { publicationName, quote, articleTitle, route, logo, articleImage } =
    entry.fields;
  return (
    <div className="Module--PressTestimonial">
      <a
        className="-link-wrapper"
        href={route}
        rel="noopener noreferrer"
        target="_blank"
      >
        <ContentfulPicture
          className="--article-image"
          alt={articleImage.fields.title}
          images={[
            {
              src: articleImage.fields.file.url,
              device: "desktop",
            },
          ]}
        />
        <p className="--quote">{quote}</p>
        <div className="-publication-wrapper">
          <ContentfulPicture
            className="--logo"
            alt={logo.fields.title}
            images={[
              {
                src: logo.fields.file.url,
                device: "desktop",
              },
            ]}
          />
          <div className="-text-wrapper">
            <p className="--publication-name">{publicationName}</p>
            <p className="--article-title">{articleTitle}</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default PressTestimonial;
