import { $PropertyType } from "utility-types";
import type { DetailPage } from "storefront/DetailPage";
import { get } from "storefront/GrailedAPI/request";
import type { Pagination } from "storefront/Pagination";

export type Response<DP> = {
  metadata: {
    pagination: Pagination;
  };
  data: Array<DP>;
};

/**
 * @name GrailedAPI.v1.DetailPages.getDetailPages
 * @param {Array<string>} slugs detail page slugs (i.e. 'all-white', 'tops'), all having the same detail page type
 * @param {DetailPage.type} type detail page type (i.e. DesignerDetailPage, CategoryDetailPage); must match specified detail page.
 * @return {Promise<Response>} a promise that resolves to a list of detail pages
 */

export type Params = {
  slugs?: $PropertyType<DetailPage, "slug">[];
  type: $PropertyType<DetailPage, "type">;
  search?: string;
  page?: number;
  per_page?: number;
};
const getDetailPages: <DP extends DetailPage>({
  slugs,
  type,
  search,
  page,
  per_page,
}: Params) => Promise<Response<DP>> = ({
  slugs,
  type,
  search,
  page,
  per_page,
}) =>
  get("/api/detail_pages", {
    slugs,
    type,
    title: search?.length ? search : undefined,
    page,
    per_page,
  });

export default getDetailPages;
