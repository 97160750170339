import React from "react";
import { getId } from "../../../Contentful/Entry";
import UserTestimonial from "./UserTestimonial";
import PressTestimonial from "./PressTestimonial";
import Carousel from "../../Carousel";
import type {
  TestimonialCarousel as TestimonialCarouselType,
  UserTestimonial as UserTestimonialType,
  PressTestimonial as PressTestimonialType,
} from "../../../Contentful/types";

type Props = {
  entry: TestimonialCarouselType;
};

/**
 * @name Modules.TestimonialCarousel
 * @description Renders the testimonials in a carousel on the trust microsite pages
 * @param {Props} props
 * @returns {React$Element<*>}
 */
const renderUserTestimonials = (userTestimonials: Array<UserTestimonialType>) =>
  userTestimonials.map((userTestimonial) => (
    <UserTestimonial key={getId(userTestimonial)} entry={userTestimonial} />
  ));

const renderPressTestimonials = (
  pressTestimonials: Array<PressTestimonialType>,
) =>
  pressTestimonials.map((pressTestimonial) => (
    <PressTestimonial key={getId(pressTestimonial)} entry={pressTestimonial} />
  ));

const TestimonialCarousel = ({ entry }: Props) => {
  const { name, userTestimonials, pressTestimonials } = entry.fields;
  return (
    <div className="Module--TestimonialCarousel">
      <h2 className="-name">{name}</h2>
      {userTestimonials && (
        <Carousel
          baseClassName="UserTestimonialCarousel"
          itemsToShow={3}
          carouselItems={renderUserTestimonials(userTestimonials)}
        />
      )}
      {pressTestimonials && (
        <Carousel
          baseClassName="PressTestimonialCarousel"
          itemsToShow={3}
          carouselItems={renderPressTestimonials(pressTestimonials)}
        />
      )}
    </div>
  );
};

export default TestimonialCarousel;
