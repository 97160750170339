import type { Device } from "storefront/lib/Device";
import type {
  ContentfulImage,
  ContentfulFit,
} from "storefront/Contentful/Image";

type Dimensions = {
  h?: number;
  w?: number;
};
export type ContentfulImageParams = {
  src: string;
  device: Device;
  size?: Dimensions;
  fit?: ContentfulFit;
};
export const toContentfulImage =
  (format: string | null | undefined) =>
  (image: ContentfulImageParams): ContentfulImage => ({
    service: "contentful",
    device: image.device,
    url: image.src,
    height: image.size && image.size.h,
    width: image.size && image.size.w,
    fit: image.fit,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    format,
  });
