import React from "react";
import ReactMarkdown from "react-markdown";
import { Paragraph, Link } from "../../../MarkdownComponents";
import GuideSingleImageModule from "../Guide/GuideSingleImageModule";
import { getId } from "../../../Contentful/Entry";
import type { ContentLaunchItem as ContentLaunchItemType } from "../../../Contentful/types";

type Props = {
  entry: ContentLaunchItemType;
};

/**
 * @name Modules.ContentLaunchItem
 * @description Renders a content launch item on a trust microsite page
 * @param {Props} props
 * @returns {React$Element<*>}
 */
const ContentLaunchItem = ({ entry }: Props) => {
  const { title, description, url, image } = entry.fields;
  return (
    <div className="Module--ContentLaunchItem">
      <a className="-link-wrapper" href={url}>
        {image && <GuideSingleImageModule key={getId(image)} entry={image} />}
        <div className="-title-description-wrapper">
          <h3 className="-title">{title}</h3>
          <ReactMarkdown
            source={description}
            renderers={{
              paragraph: Paragraph("-description"),
              link: Link("-link"),
            }}
            linkTarget="_blank"
          />
        </div>
      </a>
    </div>
  );
};

export default ContentLaunchItem;
