import { TypedEntry } from "storefront/Contentful/TypedEntry";
import FeaturedBlockEntry from "./FeaturedBlockEntry";

export const FEATURED_CONTENT = "rotatingFeaturedBanner" as const;

type Fields = {
  name: string;
  headline: string;
  featuredBlocks: Array<FeaturedBlockEntry>;
};

type FeaturedContentModuleEntry = TypedEntry<typeof FEATURED_CONTENT, Fields>;

export default FeaturedContentModuleEntry;
