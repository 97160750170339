import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash/fp";
import { pageTypeFromMap } from "storefront/Analytics/EventCreators/productAddedToWishlist";
import { Id } from "storefront/lib/Id";
import { PageType } from "storefront/Analytics/Event";
import useAnalytics from "storefront/hooks/useAnalytics";
import moduleClicked from "storefront/Analytics/EventCreators/moduleClicked";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { TrackingItemProperties } from "storefront/Contentful/types";
import { TypedEntry } from "storefront/Contentful/TypedEntry";
import getListingsFromUuid from "storefront/GrailedAPI/v1/Listings/getListingsFromUuid";
import ListingCarousel from "storefront/components/ListingCarousel";
import ListingCarouselWrapper from "storefront/components/ListingCarousel/Wrapper";
import Heading from "../Heading";

export const LISTINGS_FROM_FEED = "moduleListingsFromFeed" as const;

export type ListingsFromFeedModule = TypedEntry<
  typeof LISTINGS_FROM_FEED,
  {
    ctaText: string;
    feedId: string;
    fullWidth: boolean;
    name: string;
    title: string;
  }
>;

type Props = {
  entry: ListingsFromFeedModule;
  position: number;
  from: string;
  pageType: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
};

const ListingsFromFeed = ({
  entry,
  position,
  from,
  pageType,
  pageTypeIdentifier,
  pageTypeName,
}: Props) => {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [listings, setListings] = useState<Array<AlgoliaListing>>([]);
  const { track } = useAnalytics();

  const moduleType = "Listings From Feed";

  useEffect(() => {
    const { feedId } = entry.fields;
    getListingsFromUuid(feedId, 18).then((res) => {
      setIsFetching(false);
      setListings(res.data);
    });
  }, [entry]);

  const trackClick = (properties: TrackingItemProperties) => (): void => {
    const { name, title } = entry.fields;
    track(
      moduleClicked({
        ...properties,
        from,
        moduleType,
        moduleName: title,
        moduleNameContentful: name,
        modulePosition: position,
      }),
    );
  };

  const { title, ctaText, feedId } = entry.fields;

  const ctaTracking: TrackingItemProperties = {
    itemType: "CTA",
    itemPosition: "CTA",
    itemName: ctaText,
    itemNameContentful: ctaText,
    imageUrl: "",
  };

  if (!isFetching && isEmpty(listings)) return null;

  const productAddedToWishlistFrom = pageTypeFromMap[pageType] || pageType;

  return (
    <ListingCarouselWrapper className="Module--ListingsFromFeed">
      <Heading
        title={title}
        trackClick={trackClick(ctaTracking)}
        ctaLink={`/shop/${feedId}`}
        ctaText={ctaText}
      />

      {isFetching ? (
        <div className="-loading" />
      ) : (
        <ListingCarousel
          listings={listings}
          trackClick={trackClick}
          numOfItemsToShow={6}
          moduleName={title}
          moduleType={moduleType}
          pageType={pageType}
          pageTypeIdentifier={pageTypeIdentifier}
          pageTypeName={pageTypeName}
          from={productAddedToWishlistFrom}
        />
      )}
    </ListingCarouselWrapper>
  );
};

export default ListingsFromFeed;
