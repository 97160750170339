import { Id } from "storefront/lib/Id";
import { get } from "storefront/GrailedAPI/request";

type Response = {
  data: {
    photoUrls: Record<string, Array<string>>;
  };
};

const unpack = (res: Response): Record<Id, Array<string>> => res.data.photoUrls;

const getPhotoUrls = (ids: Array<Id>): Promise<Record<Id, Array<string>>> =>
  get<Response>("/api/designers/photo_urls", { ids }).then(unpack);

export default getPhotoUrls;
