import React from "react";
import { Link } from "react-router-dom";
import type { Event } from "storefront/Analytics/Event";
import useAnalytics from "../../../hooks/useAnalytics";
import buttonClicked from "../../../Analytics/EventCreators/buttonClicked";
import type { LaunchItem as LaunchItemType } from "./types";

type Props = {
  entry: LaunchItemType;
};

const handleOnClick =
  (url: string, track: (arg0: Event) => Event) =>
  (_: React.SyntheticEvent): void => {
    track(buttonClicked(url, "HowToSell"));
  };

const LaunchItem = ({ entry }: Props) => {
  const { title, url } = entry.fields;
  const { track } = useAnalytics();
  const howToSellGuideLink = url.match(/\/how-to-sell/);
  return (
    <div className="launch-item">
      <Link
        to={url}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '((_: SyntheticEvent<Element, Event>) => void... Remove this comment to see the full error message
        onClick={howToSellGuideLink ? handleOnClick(url, track) : null}
      >
        {`${title} →`}
      </Link>
    </div>
  );
};

export default LaunchItem;
