import React from "react";
import ReactMarkdown from "react-markdown";
import { Collapsible, Header } from "../Collapsible";
import { Link, ListItem } from "../../MarkdownComponents";
import type { FrequentlyAskedQuestionModule } from "../../Contentful/types";

type Props = {
  entry: FrequentlyAskedQuestionModule;
};

const FrequentlyAskedQuestion = ({ entry }: Props) => {
  const { question, answer } = entry.fields;
  return (
    <Collapsible
      baseClassName="Module--FrequentlyAskedQuestion"
      renderHeader={Header({
        className: "--question",
        title: question,
      })}
      renderContent={() => (
        <ReactMarkdown
          className="--answer"
          source={answer}
          renderers={{
            link: Link("-link"),
            listItem: ListItem("-list-item"),
          }}
        />
      )}
      isCollapsedByDefault
    />
  );
};

export default FrequentlyAskedQuestion;
