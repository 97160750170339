import React from "react";
import ReactMarkdown from "react-markdown";
import { Paragraph, ListItem, Link } from "../../../MarkdownComponents";
import type { TitleDescriptionItem as TitleDescriptionItemType } from "./types";

type Props = {
  entry: TitleDescriptionItemType;
};

const TitleDescriptionItem: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({ entry }) => {
  const { title, description } = entry.fields;
  return (
    <div className="title-description-item">
      <h2 className="-title">{title}</h2>
      <ReactMarkdown
        source={description}
        renderers={{
          paragraph: Paragraph("-description"),
          listItem: ListItem("-list-item"),
          link: Link("-link"),
        }}
        linkTarget="_blank"
      />
    </div>
  );
};

export default TitleDescriptionItem;
