import React from "react";
import { Icon } from "storefront/Icon";

type Props = Icon & {
  fill?: string;
};

const Star = ({
  width = "22px",
  height = "22px",
  fill = "black",
  className,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14 7.4375L11.9375 0.5H10.0625L8 7.4375H0.6875L0.125 9.125L5.9375 13.4375L3.6875 20.375L5.1875 21.5L11 17.1875L16.8125 21.5L18.3125 20.375L16.0625 13.4375L21.875 9.125L21.3125 7.4375H14Z"
      fill={fill}
    />
  </svg>
);

export default Star;
