import type { Id } from "storefront/lib/Id";
import { get } from "../../request";
import type { Capsule } from "../../../Capsule";
import type { Pagination } from "../../../Pagination";

export type Response = {
  metadata: {
    pagination: Pagination;
    totalCount: number;
  };
  data: Array<Capsule>;
};

/**
 * @name GrailedAPI.v1.Capsules.getCapsulesById
 * @param Array<Id> ids array of Capsule Ids
 * @return {Promise<Response>} returns a promise that resolves to a page of published capsules
 */
const getCapsulesById = (capsuleIds: Array<Id>): Promise<Response> =>
  get<Response>("/api/capsules", { capsuleIds });

export default getCapsulesById;
