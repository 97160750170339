import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { get } from "../../request";

type Response = {
  data: Array<AlgoliaListing>;
};

/**
 * @name GrailedAPI.v1.Listings.getListingsFromUuid
 * @param {string} uuid - the uuid of the requested feed
 * @param {?number} limit - the number of listings returned
 * @return {Response} resolves to an Array of Listings from the requested feed
 */
const getListingsFromUuid = (
  uuid: string,
  limit: number | null | undefined,
): Promise<Response> =>
  get(`/api/listings/search/${uuid}`, {
    limit,
  });

export default getListingsFromUuid;
