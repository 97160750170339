import type { Id } from "storefront/lib/Id";
import { get } from "storefront/GrailedAPI/request";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";

type Response = {
  data: Array<AlgoliaListing>;
};

const unpack = (response: Response): Array<AlgoliaListing> => response.data;

const getRecommendedSimilarListings = (
  userId: Id,
): Promise<Array<AlgoliaListing>> =>
  get(`/api/users/${userId}/recommendations/similar_listings`).then(unpack);

export default getRecommendedSimilarListings;
