import React, { Fragment } from "react";
import ReactMarkdown from "react-markdown";
import { map } from "lodash/fp";
import { getId } from "../../../Contentful/Entry";
import { Link } from "../../../MarkdownComponents";
import TitleDescriptionItem from "./TitleDescriptionItem";
import GuideSingleImageModule from "./GuideSingleImageModule";
import GuideTextModule from "./GuideTextModule";
import ContentLaunchItem from "../ContentLaunchItemCarousel/ContentLaunchItem";
import FrequentlyAskedQuestion from "../FrequentlyAskedQuestion";
import ContentfulErrorBoundary from "../ContentfulModuleWrapper/ContentfulErrorBoundary";
import type {
  GuideSectionWrapper as GuideSectionWrapperType,
  GuideSectionWrapperModule,
} from "./types";

type Props = {
  entry: GuideSectionWrapperType;
};
const contentTypeToComponent = {
  titleDescriptionItem: TitleDescriptionItem,
  guideSingleImageModule: GuideSingleImageModule,
  guideTextModule: GuideTextModule,
  contentLaunchItem: ContentLaunchItem,
  frequentlyAskedQuestion: FrequentlyAskedQuestion,
};

const renderGuideSectionWrapperModule = (module: GuideSectionWrapperModule) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const Module = contentTypeToComponent[module.contentType.toString()];
  if (!Module) return <Fragment key={getId(module)} />;
  return (
    <ContentfulErrorBoundary key={getId(module)}>
      <Module key={getId(module)} entry={module} />
    </ContentfulErrorBoundary>
  );
};

const renderGuideSectionWrapperModules = (
  modules: [GuideSectionWrapperModule],
) => (
  <div className="GuideSectionWrapper--Modules">
    {map(renderGuideSectionWrapperModule, modules)}
  </div>
);

const GuideSectionWrapper: (
  arg0: Props,
) => React.ReactElement<React.ComponentProps<any>, any> = ({ entry }) => {
  const { title, description, modules } = entry.fields;
  return (
    <div className="Module--GuideSectionWrapper">
      <div className="-title-description-wrapper">
        <h2 className="-title">{title}</h2>
        <ReactMarkdown
          className="-description"
          source={description}
          renderers={{
            link: Link("-link"),
          }}
        />
      </div>
      {renderGuideSectionWrapperModules(modules)}
    </div>
  );
};

export default GuideSectionWrapper;
