import React from "react";
import { range } from "lodash/fp";
import round from "lodash/round";
import cn from "classnames";
import RatingStar from "storefront/components/Icons/Information/RatingStar";
import ReactTooltipLite from "storefront/components/ReactTooltipLite";
import styles from "./RatingStars.module.scss";

const NUMERIC_RATING_PRECISION = 2;

export const STAR_COLORS = [
  "#d0021b",
  "#f59a05",
  "#8ca402",
  "#009c00",
  "#007b4c",
];

// red, orange, light green, green, forest
// given a rating rounded to nearest 0.5 and the number of a star, return
// the correct variant - IE is it a full star, half star, or empty star
const starClassName = (
  roundedRating: number,
  starNumber: number,
): "full" | "half" | "empty" => {
  if (roundedRating >= starNumber) {
    return "full";
  }

  if (Math.ceil(roundedRating) === starNumber) {
    return "half";
  }

  return "empty";
};

type StarProps = {
  roundedRating: number;
  starNumber: number;
  starColor: string;
};

const StarWrapper = ({ roundedRating, starNumber, starColor }: StarProps) => {
  return (
    <span className={styles.starWrapper}>
      <RatingStar
        fill={starColor}
        variant={starClassName(roundedRating, starNumber)}
        className={styles.star}
      />
    </span>
  );
};

export const ratingColor = (averageRating: number): string =>
  STAR_COLORS[Math.round(averageRating) - 1];

const toolTipLabel = (value: number): string =>
  `${round(value, NUMERIC_RATING_PRECISION)}/5 Feedback`;

type Props = {
  value: number;
  className?: string;
};

const RatingStars = ({ value, className = "" }: Props) => {
  const ratingRoundedToHalf = Math.round(value * 2) / 2;
  const starColor = ratingColor(value);

  return (
    <ReactTooltipLite
      className={cn("RatingStars", styles.ratingStars, className)}
      content={toolTipLabel(value)}
      direction="up-center"
      tipContentHover
    >
      {range(1, 6).map((index) => (
        <StarWrapper
          key={index}
          roundedRating={ratingRoundedToHalf}
          starNumber={index}
          starColor={starColor}
        />
      ))}
    </ReactTooltipLite>
  );
};

export default RatingStars;
