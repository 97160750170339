import React from "react";
import { Asset } from "contentful";
import { LARGER_THAN_MOBILE, MOBILE } from "storefront/hooks/useMediaQuery";
import AutoplayVideo from "storefront/components/AutoplayVideo";

const getSource = (video: Asset | undefined, image: Asset, media: string) =>
  video
    ? {
        src: video.fields.file.url,
        type: video.fields.file.contentType,
        poster: image.fields.file.url,
        media,
        description: image.fields.description,
      }
    : undefined;

type VideoOrImageProps = {
  mobileVideo?: Asset;
  mobileImage: Asset;
  desktopVideo?: Asset;
  desktopImage: Asset;
  className?: string;
};

const VideoOrImage = ({
  mobileVideo,
  mobileImage,
  desktopVideo,
  desktopImage,
  className,
}: VideoOrImageProps) => {
  const mobileVideoSource = getSource(mobileVideo, mobileImage, MOBILE);
  const desktopVideoSource = getSource(
    desktopVideo,
    desktopImage,
    LARGER_THAN_MOBILE,
  );

  if (desktopVideoSource && mobileVideoSource) {
    return (
      <AutoplayVideo
        className={className}
        sources={[mobileVideoSource, desktopVideoSource]}
        fallback={desktopVideoSource}
      />
    );
  }

  return (
    <picture>
      <source media="(max-width: 768px)" src={mobileImage.fields.file.url} />
      <source media="(min-width: 769px)" src={desktopImage.fields.file.url} />
      <img
        src={desktopImage.fields.file.url}
        alt={desktopImage.fields.description}
        className={className}
      />
    </picture>
  );
};

export default VideoOrImage;
