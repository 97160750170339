import { useState, useEffect } from "react";
import webpIsSupported from "../lib/Browser/isWebpSupported";
import bestFor from "../lib/Device/bestFor";
import type { ContentfulImage } from "../Contentful/Image";
import src from "../Contentful/Image/src";
import srcSet from "../Contentful/Image/srcSet";
import useCurrentDevice from "./useCurrentDevice";

type Sources = [string, string];

const toSources = (image: ContentfulImage): Sources => [
  src(image),
  srcSet(image),
];

// This is the data uri encoding for the smallest possible transparent GIF.
// https://stackoverflow.com/a/9967193
const fallbackSource =
  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

const useImagesSource = (images: Array<ContentfulImage>): Sources => {
  const device = useCurrentDevice();
  const [sources, setSources] = useState<[string, string]>([
    fallbackSource,
    "",
  ]);
  const image = bestFor(device)(images);
  useEffect(() => {
    if (!image) return;
    webpIsSupported()
      .then((supported: boolean): ContentfulImage => {
        if (supported && !image.format) {
          image.format = "webp";
        }

        return image;
      })
      .then(toSources)
      .then(setSources);
  }, [image]);
  return sources;
};

export default useImagesSource;
