import { post } from "storefront/GrailedAPI/request";
import type { Id } from "storefront/lib/Id";

type Response = {
  data: {
    followed: number;
  };
};

/**
 * @name GrailedAPI.v1.Users.Follows.followDesigners
 * @param {UserId}
 * @return {Promise<Response>}
 */
const followDesigners = (
  userId: Id,
  designerIds: Array<Id>,
): Promise<Response> =>
  post(`/api/users/${userId}/follows/designers`, {
    designerIds,
  });

export default followDesigners;
