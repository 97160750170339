import React from "react";
import { map } from "lodash/fp";
import { getId } from "../../../Contentful/Entry";
import ImageItem from "./ImageItem";
import type {
  ImageItem as ImageItemType,
  GuideImageModule as GuideImageModuleType,
} from "./types";

type Props = {
  entry: GuideImageModuleType;
};

const renderImageItem = (imageItem: ImageItemType) => (
  <ImageItem key={getId(imageItem)} entry={imageItem} />
);

const GuideImageModule = ({ entry }: Props) => {
  const { imageItems } = entry.fields;
  return (
    <div className="Module--GuideImage">{map(renderImageItem, imageItems)}</div>
  );
};

export default GuideImageModule;
